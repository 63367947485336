import * as React from "react";
import { Link, graphql } from "gatsby";
import Header from "../components/includes/defaults/header";
import Cta from "../components/CTA/CTAMain";
import Layout from "../components/Defaults/Layout";
import Footer from "../components/includes/defaults/footer";
import Seo from "../components/seo";
var slugify = require("slugify");

const govArea = ({ pageContext, data }) => {
	const { local_government_area } = pageContext;

	return (
		<>
			<Layout>
				<Cta background={true} />
				<div className="mt-6 mx-auto  max-w-screen-xl items-center mb-10 justify-between px-4 ">
					<div className=" bg-slate-900/90 my-10 to-white shadow-xl p-4 rounded outline outline-[4px] hover:outline-[5px] outline-slate-500/20 hover:outline-blue-500/80 outline-offset-[6px] hover:outline-offset-0 duration-300">
						<div className="grid grid-cols-2 md:grid-cols-6   ">
							<div className="col-span-2 sm:col-span-6  flex my-auto ">
								<Link
									to="/areas"
									className="w-full text-center text-white rounded    "
								>
									All Our Areas
								</Link>
							</div>
						</div>
					</div>
					<div className="bg-slate-800 my-10 shadow-xl p-10 max-h-96 overflow-auto  text-center rounded outline outline-[4px] hover:outline-[5px] outline-blue-500/20 hover:outline-blue-500/80 outline-offset-[6px] hover:outline-offset-0 duration-300">
						<h1 className="font-bold mb-4 text-xl text-white ">
							Areas we cover in {local_government_area}
						</h1>
						<div className="mt-6 grid grid-cols-1 md:grid-cols-6 gap-6 ">
							{data?.allBranchesCsv?.localArea?.map((localArea, indx) => {
								return (
									<div className="col-span-1    md:col-span-2   rounded p-6 outline outline-[3px] hover:outline-[3px] outline-blue-500/10 hover:outline-blue-500/60 outline-offset-[5px] hover:outline-offset-0 duration-300">
										<a
											className="
                   text-white text-base hover:text-blue-400"
											href={`#${localArea?.fieldValue}`}
										>
											{localArea?.fieldValue}
										</a>
									</div>
								);
							})}
						</div>
					</div>
					<div className="mt-6 mx-auto  ">
						{data?.allBranchesCsv?.localArea?.map((localArea, indx) => {
							return (
								<>
									{" "}
									<div id={localArea?.fieldValue} className=" my-10">
										<h3 className="text-blue-400 mb-6 text-2xl ">
											{localArea?.fieldValue}
										</h3>
										{/* {JSON.stringify(localArea)} */}
										<div className="grid grid-cols-1 md:grid-cols-4 gap-6 divide divide-y">
											{localArea?.serviceGroup?.map((serviceGroup, index) => {
												return (
													<>
														{serviceGroup?.edges?.map((localAreas, index) => {
															var path1 = localAreas?.node?.related_service;
															var path2 = localAreas?.node?.name;

															return (
																<div className="col-span-1   md:col-span-2 shadow shadow  rounded p-6 outline outline-[3px] hover:outline-[3px] outline-blue-500/10 hover:outline-blue-500/60 outline-offset-[5px] hover:outline-offset-0 duration-300">
																	<Link
																		to={`/${slugify(path1, {
																			lower: true,
																		})}/${slugify(path2, {
																			lower: true,
																		})}`}
																	>
																		<p className=" text-gray-500  tracking-wide ">
																			{localAreas?.node?.Article_Title}
																		</p>
																	</Link>
																</div>
															);
														})}
													</>
												);
											})}
										</div>
									</div>
								</>
							);
						})}
					</div>
				</div>
			</Layout>
		</>
	);
};
export const Head = ({
	pageContext: { local_government_area },
	data: {
		site: {
			siteMetadata: { mainService, phoneNum },
		},
	},
}) => {
	const description = `We provide ${mainService} services in ${local_government_area}. Check out the areas we cover in ${local_government_area}. We have a team of experts who are ready to help you with your ${mainService} needs. Call us on ${phoneNum}`;
	return (
		<Seo
			title={`Areas We Cover in ${local_government_area}`}
			description={description}
		/>
	);
};

export const query = graphql`
	query ($local_government_area: String) {
		allBranchesCsv(
			filter: { local_government_area: { eq: $local_government_area } }
		) {
			localArea: group(field: { name: SELECT }) {
				serviceGroup: group(field: { related_service: SELECT }, limit: 1) {
					edges {
						node {
							name
							related_service
							Article_Title
						}
					}
					fieldValue
					field
				}
				fieldValue
				field
			}
		}
		site {
			siteMetadata {
				mainService
				phoneNum
			}
		}
	}
`;

export default govArea;
