import React, { useState, Fragment } from "react";
// import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { Transition, Menu } from "@headlessui/react";
// import tw from "twin.macro"
import { Link } from "gatsby";
// import tw from "twin.macro"
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const NavLinks = [
	{
		name: "Home",
		href: "/",
	},
	{
		name: "About",
		href: "/about",
	},
	{
		name: "Services",
		href: "/services",
		subMenu: [
			{
				name: "Chimney Repair",
				href: "/chimney-repair",
			},
			{
				name: "Commercial Roofing",
				href: "/commercial-roofing",
			},
			{
				name: "Domestic Roofing",
				href: "/domestic-roofing",
			},
			{
				name: "Flat Roofing",
				href: "/flat-roofing",
			},
			{
				name: "Guttering",
				href: "/guttering",
			},
			{
				name: "Industrial Roofing",
				href: "/industrial-roofing",
			},
			{
				name: "Roof Insulation",
				href: "/roof-insulation",
			},
			{
				name: "Roof Leak Repairs",
				href: "/roof-leak-repairs",
			},

			{
				name: "Roof Repairs",
				href: "/roof-repairs",
			},
			{
				name: "Roofing",
				href: "/roofing",
			},
			{
				name: "Roofing Contractors",
				href: "/roofing-contractors",
			},
			{
				name: "Roofing Cost",
				href: "/roofing-cost",
			},
			{
				name: "Roofing Services",
				href: "/roofing-services",
			},
		],
	},
	{
		name: "Quote",
		href: "/quote",
	},
	{
		name: "Contact",
		href: "/contact",
	},
];

const Header = ({ phone_number }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { site } = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					description
					phoneNum
				}
			}
		}
	`);
	const defaultNum = phone_number ? phone_number : site?.siteMetadata.phoneNum;
	return (
		<>
			<header
				aria-label="Site Header"
				className="shadow-sm border-t-4 border-t-slate-400"
			>
				<div className="mx-auto  py-4 max-w-screen-xl   justify-between px-4">
					<div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
						<div className="col-span-1 text-center lg:text-left justify-between lg:col-span-9  ">
							<div className="lg:inline-flex  inline-block object-center">
								<Link to="/">
									<StaticImage
										src="../../../images/branding/logo.png"
										alt="A1 Roofing Surrey"
										placeholder="blurred"
										className=" flex-auto mx-auto h-20 object-center "
										height={200}
									/>
									{/* <img
										src="https://uk-skiphire.co.uk/images/skip-hire.png"
										className=" flex-auto mx-auto h-20 object-center "
									/> */}

									<span className="sr-only">Logo</span>

									<span className="h-10 w-20 rounded-lg bg-gray-200"></span>
								</Link>
								<div className="my-auto mx-auto ml-4 ">
									<h1
										className="text-2xl font-semibold text-slate-600"
										// css={[
										//   tw`text-2xl font-semibold text-blue-${
										//     styling.primary_accent ? 600 : 600
										//   }`,
										// ]}
									>
										<Link to="/">{site?.siteMetadata?.title}</Link>
									</h1>
									<p className="flex-wrap text-left  font-thin">
										We provide the best Roofing Services in the Surrey
										{/* {styles["secondary_accent"]} */}
										{/* {typeof styles.primary} */}
									</p>
								</div>
							</div>
						</div>

						<div className="col-span-1  my-auto mx-auto lg:text-right text-center lg:col-span-3 ">
							<div className="  w-full flex  ">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className=" text-slate-600 h-8 w-8 mr-4 my-auto outline outline-[2px] rounded-full p-1"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									strokeWidth="2"
									stroke="currentColor"
									fill="none"
									strokeLinecap="round"
									strokeLinejoin="round"
								>
									<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
									<path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
									<path d="M15 6l2 2l4 -4"></path>
								</svg>
								<a href="tel:01452 452049" className="font-medium  my-auto  ">
									<p className="text-gray-800 font-bold ">{defaultNum}</p>
									<p className="flex-wrap text-sm font-thin text-gray-600 ">
										Get in touch with us
									</p>
								</a>
							</div>
						</div>
					</div>
				</div>
				{/* Second Line */}

				<div>
					<nav className=" bg-slate-800 shadow-lg border-t-4 border-t-slate-100  ">
						<div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
							<div className=" py-2">
								<div className="flex w-full my-auto items-center">
									<div className="hidden lg:block">
										<div className=" flex items-baseline space-x-4">
											{NavLinks.map((link, index) => {
												if ("subMenu" in link) {
													return (
														<div key={index}>
															<Menu
																as="div"
																className="relative z-40  text-left"
															>
																<div>
																	<Menu.Button className="inline-flex w-full justify-center text-white hover:text-white px-3 py-2 rounded drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]  ">
																		{link.name}
																		<ChevronDownIcon
																			className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
																			aria-hidden="true"
																		/>
																	</Menu.Button>
																</div>
																<Transition
																	as={Fragment}
																	enter="transition ease-out duration-100"
																	enterFrom="transform opacity-0 scale-95"
																	enterTo="transform opacity-100 scale-100"
																	leave="transition ease-in duration-75"
																	leaveFrom="transform opacity-100 scale-100"
																	leaveTo="transform opacity-0 scale-95"
																>
																	<Menu.Items
																		as="div"
																		className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
																	>
																		{link.subMenu.map((sub, index) => {
																			return (
																				<div className="px-1 py-1" key={index}>
																					<Link to={sub.href}>
																						<Menu.Item>
																							{({ active }) => (
																								<div
																									className={`${
																										active
																											? "bg-blue-500 text-white"
																											: "text-gray-900"
																									} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
																								>
																									{sub.name}
																								</div>
																							)}
																						</Menu.Item>
																					</Link>
																				</div>
																			);
																		})}
																	</Menu.Items>
																</Transition>
															</Menu>
														</div>
													);
												} else {
													return (
														<Link
															key={index}
															to={link.href}
															className="text-white hover:text-white px-3 py-2 rounded drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]"
														>
															{link.name}
														</Link>
													);
												}
											})}
										</div>
									</div>
									<div className="ml-auto hidden lg:block">
										<Link
											to="/quote"
											// type="button"
											className=" p-2 text-center text-white rounded bg-slate-800/90 outline outline-[0.1px] outline-offset-[3px] hover:outline-offset-0 hover:bg-blue-700/80 duration-150 "
										>
											Get A Quote
										</Link>
									</div>
								</div>
								<div className="-mr-2 flex lg:hidden">
									<button
										onClick={() => setIsOpen(!isOpen)}
										type="button"
										className="bg-blue-500 float-left p-2 rounded-md text-white hover:text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-gray-800 focus:ring-white"
										aria-controls="mobile-menu"
										aria-expanded="false"
									>
										<span className="sr-only">Open main menu</span>
										{!isOpen ? (
											<svg
												className="block h-6 w-6"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												aria-hidden="true"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M4 6h16M4 12h16M4 18h16"
												/>
											</svg>
										) : (
											<svg
												className="block h-6 w-6"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												aria-hidden="true"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M6 18L18 6M6 6l12 12"
												/>
											</svg>
										)}
									</button>
								</div>
							</div>
						</div>

						<Transition
							show={isOpen}
							enter="transition ease-out duration-100 transform"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="transition ease-in duration-75 transform"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							{(ref) => (
								<div className=" lg:hidden" id="mobile-menu">
									<div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
										{NavLinks.map((link, index) => {
											if ("subMenu" in link) {
												return (
													<>
														<Menu
															as="div"
															key={index}
															className=" block text-left"
														>
															<div>
																<Menu.Button className="inline-flex w-full  text-white hover:text-white px-3 py-2 rounded drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]  ">
																	{link.name}
																	<ChevronDownIcon
																		className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
																		aria-hidden="true"
																	/>
																</Menu.Button>
															</div>
															<Transition
																as={Fragment}
																enter="transition ease-out duration-100"
																enterFrom="transform opacity-0 scale-95"
																enterTo="transform opacity-100 scale-100"
																leave="transition ease-in duration-75"
																leaveFrom="transform opacity-100 scale-100"
																leaveTo="transform opacity-0 scale-95"
															>
																<Menu.Items
																	as="div"
																	className=" mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
																>
																	{link.subMenu.map((sub, index) => {
																		return (
																			<div className="px-1 py-1" key={index}>
																				<Link to={sub.href}>
																					<Menu.Item>
																						{({ active }) => (
																							<div
																								className={`${
																									active
																										? "bg-blue-500 text-white"
																										: "text-gray-900"
																								} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
																							>
																								{sub.name}
																							</div>
																						)}
																					</Menu.Item>
																				</Link>
																			</div>
																		);
																	})}
																</Menu.Items>
															</Transition>
														</Menu>
													</>
												);
											} else {
												return (
													<Link
														key={index}
														to={link.href}
														className="hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
													>
														{link.name}
													</Link>
												);
											}
										})}
									</div>
								</div>
							)}
						</Transition>
					</nav>
				</div>
			</header>
		</>
	);
};

export default Header;
